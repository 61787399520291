import React from 'react';
import BenicioRouter from './Components/Routes';

function App() {
  return (
    <div className="App">
      <BenicioRouter />
    </div>
  );
}

export default App;
